import React, { Fragment } from 'react';

import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Fragment>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
  </Fragment>
);

export default NotFoundPage;
